import React from "react";
import { useEffect, useState } from "react";

export const Age = () => {
  const [age, setAge] = useState("unknown");

  useEffect(() => {
    fetch("https://dakkra.com/api/age")
      .then((response) => response.json())
      .then((data) => setAge(data))
      .catch((param) => console.log("Couldn't fetch age endpoint"));
  }, []);

  return (
    <div>Age {age}</div>
    );
};
