import "./App.css";
import Panel from "./Panel";
import { Fog } from "./Fog";
import { PanelTitle } from "./PanelTitle";
import Head from "./images/head.jpg";
import { Age } from "./Age";
import React, { useEffect, useState, useMemo } from "react";

function App() {
  const buttonStates = useMemo(() => ["contact@dakkra.com", "Copied to clipboard"], []);
  const [buttonTxt, setButtonText] = useState(buttonStates[0]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (buttonTxt === buttonStates[1]) {
      timeout = setTimeout(() => {
        setButtonText(buttonStates[0]);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [buttonTxt, buttonStates]);

  function handleButtonClick() {
    setButtonText(buttonStates[1]);
    //Copy stuff to clipboard
    navigator.clipboard.writeText(buttonStates[0]);
  }

  return (
    <div className={"App"}>
      <Panel>
        <div className="block">
          <h1>Dakkra</h1>
        </div>
        <div className="block">
          Software Engineer
          <br />
          Digital Musician
          <br />
          Multi-media specialist
          <br />
        </div>
        <br />
        <Fog></Fog>
        <hr />
        <div className="block">
          <img id="face" alt="no" src={Head} />
        </div>
        <div className="block">
          Eurydice Soderquist
          <br />
          <Age></Age>
          <br />
          <a href="setup" target="_blank" rel="noreferrer">
            System Specs
          </a>
        </div>
      </Panel>

      <Panel>
        <PanelTitle>Programming</PanelTitle>
        <hr />
        <a href="http://github.com/Dakkra" target="_blank" rel="noreferrer">
          Github
        </a>
        <a id="notepadLinkElem" target="_blank" href="notepad" rel="noreferrer">
          Notepad
        </a>
        <a target="_blank" href="tictactoe" rel="noreferrer">
          TicTacToe
        </a>
        <a target="_blank" href="18Years" rel="noreferrer">
          Sapphire Quest
        </a>
        <a target="_blank" href="http://soniccandle.sourceforge.net/" rel="noreferrer">
          Sonic Candle
        </a>
      </Panel>

      <Panel>
        <PanelTitle>Music</PanelTitle>
        <hr />
        <a target="_blank" href="https://open.spotify.com/artist/4G6gi3s23tnjk5aP31nvMY?si=1wzZk287SfqihIbYGqPppA" rel="noreferrer">
          Spotify
        </a>
        <a target="_blank" href="https://music.apple.com/us/artist/dakkra/1610263334" rel="noreferrer">
          Apple Music
        </a>
        <a target="_blank" href="https://www.amazon.com/music/player/artists/B09SKSKFY8/dakkra" rel="noreferrer">
          Amazon Music
        </a>
        <a target="_blank" href="http://soundcloud.com/Dakkra" rel="noreferrer">
          SoundCloud
        </a>
        <a target="_blank" href="http://youtube.com/DakkraOfficial" rel="noreferrer">
          YouTube
        </a>
        <a target="_blank" href="https://dakkra.bandcamp.com/" rel="noreferrer">
          Bandcamp
        </a>
      </Panel>

      <Panel>
        <PanelTitle>Contact</PanelTitle>
        <hr />
        Discord:{" "}
        <a title="Discord Channel, click to join" target="_blank" href="http://dakkra.com/discord" rel="noreferrer">
          dakkra.com/discord
        </a>
        <br />
        Email:
        <button
          onClick={() => {
            handleButtonClick();
          }}
        >
          {buttonTxt}
        </button>
      </Panel>

      <Panel>&copy; {new Date().getFullYear()} Christopher Soderquist</Panel>
    </div>
  );
}

export default App;
