import React, { useEffect, useState, useMemo } from "react";

export function Fog() {
  const messages = useMemo(
    () => [
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐-_-)",
      "Full On Geek (⌐>_O)",
      "Full On Geek (⌐-_-)",
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐■_■)",
      "Full On Geek (⌐■_■)",
    ],
    []
  );

  const [counter, setCounter] = useState(0);
  const [message, setMessage] = useState(messages[counter]);
  useEffect(() => {
    const updateCounter = () => {
      const newval = (counter + 1) % messages.length;
      setCounter(newval);
      setMessage(messages[newval]);
    };

    const myTimeout = setTimeout(updateCounter, 250);
    return () => {
      clearTimeout(myTimeout);
    };
  }, [counter, message, messages]);

  return <div id="fog">{message}</div>;
}
