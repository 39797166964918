import React, { ReactNode } from "react";
import { useWindowWidthAndHeight } from "./CustomHooks/WindowSizeHook";

interface Props{
  "children" : ReactNode,
}

const Panel = (props: Props) => {

  return <div className={
    (useWindowWidthAndHeight().at(0) ?? 1000) > 700 ? "panel" : "panel panelSmall"
  }>{props.children}</div>;
};

export default Panel;
